<template>
	<v-sheet class="location" id="location" style="height: calc(100vh - 88px)">
		<CreateDialog
			:dialog="createDialog"
			v-on:close="
				createDialog = false;
				clearLocalData();
			"
			v-on:scroll="hideOnScroll($event)"
		>
			<template v-slot:title>
				<span>{{ pageTitle() }}</span>
				<span class="orange--text text--darken-4 mr-2 barcode-font-size">
					#{{ purchase_order.barcode }}</span
				>
				<v-chip
					v-if="statusapprove == 'approve'"
					label
					:color="`${getStatusColor(purchase_order.status_value)} white--text text-capitilize`"
					class="mr-2"
				>
					{{ purchase_order.status_value }}
				</v-chip>
			</template>
			<!-- <template v-if="scroll_top" v-slot:additional>
				<span>{{ formatMoney(line_items_calculations.total) }}</span>
			</template> -->
			<template v-slot:body>
				<template v-if="!barcodeLoading">
					<v-form
						ref="serviceForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateOrCreate"
					>
						<v-row>
							<v-col md="10">
								<v-row class="px-4">
									<!-- <v-col md="3" class="my-auto py-0">
										<label for="purchase-order-id" class="btx-label mt-2 required">Purchase Order #</label>
									</v-col>
									<v-col md="9" class="py-0">
										<TextInput
											hide-details
											:disabled="true"
											:loading="pageLoading"
											id="purchase-order-id"
											placeholder="Purchase Order #"
											:rules="[
												vrules.required(purchase_order.barcode, 'Purchase Order #'),
												barcodeError ? false : true,
											]"
											:class="{
												required: !purchase_order.barcode,
											}"
											v-model="purchase_order.barcode"
											v-on:change="validateBarcode(purchase_order.barcode, 'purchase-order'), savetoLocal()"
										>
										</TextInput>
										<span v-if="barcodeError" class="red--text text--darken-1 font-small">{{
											barcodeError
										}}</span>
									</v-col> -->
									<v-col md="2" class="my-auto py-0">
										<label for="po-number" class="btx-label mt-2">
											Ref Number
											<TooltipQuestion>
												<template v-slot:text>Reference Number Printed on pdf</template>
											</TooltipQuestion>
										</label>
									</v-col>
									<v-col md="4" class="py-0">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="po-number"
											placeholder="Ref Number"
											v-model="purchase_order.reference_number"
											@change="savetoLocal()"
										>
										</TextInput>
									</v-col>
									<v-col md="2" class="py-0" v-if="false">
										<label for="description" class="btx-label mt-2">Description</label>
									</v-col>
									<v-col md="9" class="py-0" v-if="false">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="description"
											placeholder="Description"
											v-model="services.description"
										>
										</TextAreaInput>
									</v-col>
									<v-col md="2" class="my-auto py-0 text-right">
										<label for="Delivery-Date" class="btx-label mt-2 text-right required"
											>Delivery Date
										</label>
									</v-col>
									<v-col md="3" class="py-0">
										<DatePicker
											hide-details
											:min-date="minDate"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="Delivery-Date"
											placeholder="Delivery Date"
											v-model="purchase_order.date"
											@change="savetoLocal()"
											:rules="[vrules.required(purchase_order.date, 'Delivery Date')]"
											:class="{
												required: !purchase_order.date,
											}"
										></DatePicker>
									</v-col>
									<v-col md="2" class="mt-2 py-0">
										<label for="customer" class="btx-label mt-2">Customer</label>
									</v-col>
									<v-col md="9" class="py-0">
										<SelectCustomerSupplier
											v-model="customer"
											:items="customerList"
											:update-detail="selectedCustomer"
											type="customer"
											placeholder="Customer"
											input-id="customer-id"
											@change="getRelatedProject($event), savetoLocal()"
										></SelectCustomerSupplier>
									</v-col>
									<template v-if="customer && customer.id">
										<v-col md="2" class="my-auto py-0">
											<label for="Project" class="btx-label mt-2">Project</label>
										</v-col>
										<v-col md="9" class="py-0">
											<AutoCompleteInput
												hide-details
												:items="projectList"
												:disabled="pageLoading"
												:loading="pageLoading"
												id="Project"
												placeholder="Project"
												v-model="purchase_order.project"
												@change="savePurchaseOrderToLocal()"
											>
											</AutoCompleteInput>
										</v-col>
									</template>
									<v-col md="2" class="mt-2 py-0">
										<label for="supplier" class="btx-label mt-2 required">Supplier</label>
									</v-col>
									<v-col md="9" class="py-0">
										<SelectSupplier
											v-model="supplier"
											:items="supplierList"
											:update-detail="selectedSupplier"
											type="supplier"
											placeholder="Supplier"
											input-id="supplier-id"
											:rules="[vrules.required(supplier.id, 'Supplier')]"
											:class="{
												required: !supplier.id,
											}"
											:disabled="purchase_order.id ? true : false"
											@change="supplierChange($event)"
										></SelectSupplier>
									</v-col>
								</v-row>
							</v-col>
							<v-col md="2">
								<v-row>
									<v-col md="12" class="py-0 text-right">
										<ImageUpload
											v-if="false"
											can-change
											v-model="services.image"
											:page-loading="pageLoading"
										></ImageUpload>
										<div class="py-1 mb-2" v-if="false">
											<span> # </span
											><b class="orange--text text--darken-4" style="font-size: 16px">{{
												purchase_order.barcode
											}}</b>
										</div>
										<!-- <span class="fw-500" style="font-size: 24px">Amount</span><br />
										<div class="blue--text text--darken-4 fw-500 mt-5" style="font-size: 30px">
											{{ formatMoney(line_items_calculations.total) }}
										</div> -->
									</v-col>
								</v-row>
							</v-col>
							<v-col md="12">
								<p class="middle-header-background mb-2 px-4">
									Line items <br />
									<span
										><v-icon small>mdi-progress-question</v-icon> Specify the Line Items for your Request PO
										</span
									>
								</p>
							</v-col>
							<v-col md="12">
								<POLineItems
									ref="poLineItemsRef"
									v-model="poLineItems"
									:statusapprove="statusapprove"
									:supplier="supplier.id"
									:requestpo="requestpoid"
									:calculations="line_items_calculations"
									@update:total="getCalculation($event)"
								></POLineItems>
							</v-col>
							<v-col md="12" v-if="statusapprove != 'approve' && !uuid">
								<p class="middle-header-background mb-2 px-4">
									Attachments & Other details<br />
									<span>
										<v-icon small>mdi-progress-question</v-icon> Specify the Attachments & Other details for
										your Request Po</span
									>
								</p>
							</v-col>
							<v-col md="10">
								<v-row>
									<template v-if="statusapprove != 'approve' && !uuid">
										<v-col md="2" class="mt-2 py-0">
											<label for="remark" class="btx-label mt-2">Attachments</label>
										</v-col>
										<v-col md="9" class="py-0">
											<FileUpload v-model="purchase_order.files"></FileUpload>
										</v-col>
									</template>
									<v-col md="12" class="py-5"> </v-col>
									<!-- <v-col md="2" class="mt-2 py-0">
										<label for="remark" class="btx-label mt-2">
											Remark
											<TooltipQuestion>
												<template v-slot:text>Remark Printed on pdf</template>
											</TooltipQuestion>
										</label>
									</v-col> -->
									<!-- <v-col md="9" class="py-0">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="remark"
											placeholder="Remark"
											v-model="purchase_order.admin_remark"
											@change="savetoLocal()"
										>
										</TextAreaInput>
									</v-col> -->
									<!-- <v-col md="2" class="py-0">
										<label for="terms-and-conditions" class="btx-label mt-2">Terms & Conditions</label>
									</v-col>
									<v-col md="9" class="py-0">
										<SelectTermsCondition
											v-model="terms_and_condition"
											label="Terms & conditions"
											placeholder="Terms & conditions"
											type="request-order"
											append-outer-icon="mdi-cog"
										></SelectTermsCondition>
									</v-col> -->
									<!-- <v-col md="3" class="py-0">
										<label for="terms-and-conditions" class="btx-label mt-2">Terms & Conditions</label>
									</v-col>
									<v-col md="9" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="termsAndConditionList"
											:disabled="pageLoading"
											:loading="pageLoading"
											placeholder="Terms and conditions"
											v-model="purchase_order.terms_and_conditions"
											@change="savetoLocal()"
										>
										</AutoCompleteInput>
									</v-col>
									<v-col md="3" class="py-0">
										<label for="terms-and-conditions" class="btx-label mt-2"></label>
									</v-col>
									<v-col md="9" class="py-0">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="terms-and-conditions"
											placeholder="Terms & Conditions"
											v-model="purchase_order.terms_and_conditions_text"
											@change="savetoLocal()"
										>
										</TextAreaInput>
									</v-col> -->
								</v-row>
							</v-col>

							<template v-if="statusapprove == 'approve'">
								<v-col md="12">
									<p class="middle-header-background mb-2 px-4">
										Approver Comments & Attachments<br />
										<!-- <span>
										<v-icon small>mdi-progress-question</v-icon> Specify the Attachments & Other details for
										your Request Po</span
									> -->
									</p>
								</v-col>

								<v-col md="2" class="mt-2 py-0">
									<label for="remark" class="btx-label mt-2">Attachments</label>
								</v-col>
								<v-col md="9" class="py-0">
									<FileUpload v-model="purchase_order.files_approve"></FileUpload>
								</v-col>
								<v-col md="12" class="py-5"> </v-col>
								<v-col md="2" class="mt-2 py-0">
									<label for="remark" class="btx-label mt-2"> Remark </label>
								</v-col>
								<v-col md="9" class="py-0">
									<TextAreaInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="remark"
										placeholder="Remark"
										v-model="purchase_order.approve_remark"
										@change="savetoLocal()"
									>
									</TextAreaInput>
								</v-col>
							</template>
						</v-row>
					</v-form>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="statusapprove != 'approve'"
					tile
					depressed
					color="blue darken-4"
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					@click="updateOrCreate('save')"
				>
					Save Draft
				</v-btn>
				<v-btn
					v-if="statusapprove != 'approve' && purchase_order.status != 7"
					tile
					depressed
					color="cyan darken-4"
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					@click="updateOrCreate('pending_for_approved')"
				>
					Submit For Approval
				</v-btn>
				<v-btn
					v-if="statusapprove == 'approve'"
					tile
					depressed
					color="cyan darken-4"
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					@click="updateOrCreate('approved')"
				>
					Approve
				</v-btn>
			</template>
		</CreateDialog>
		<ManageCategory
			:dialog="manageCategoryDialog"
			:category="groupList"
			v-on:close-dialog="manageCategoryDialog = false"
			v-on:success="getCategories"
		></ManageCategory>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
// import {toNumber} from "lodash";
import { SET_ERROR, SET_MESSAGE, GET_PROJUCT_DROPDOWN } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
// import { CreateService, UpdateService, GetService } from "@/core/lib/service.lib";
import {
	GetCustomer,
	CreateRequestPo,
	UpdatePurchaseOrder,
	GetPurchaseOrder,
} from "@/core/lib/request-order.lib";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import SelectSupplier from "@/view/components/SelectSupplier";
import ImageUpload from "@/view/components/ImageUpload";
import ManageCategory from "@/view/components/Manage-Category";
import CreateDialog from "@/view/components/CreateDialog";
import POLineItems from "@/view/components/RequestPoLineIteam";
import FileUpload from "@/view/components/FileUpload";
//import SelectTermsCondition from "@/view/components/SelectTermsCondition";
import SelectCustomerSupplier from "@/view/components/SelectCustomerSupplier";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import moment from "moment-timezone";
import { EventBus } from "@/core/event-bus/event.bus";
// import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { filter, map, toSafeInteger } from "lodash";
import { saveData, getData, deleteData } from "@/core/services/local.service";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
	name: "Purchase-order-create",
	title: "Create Purchase Order",
	data() {
		return {
			statusapprove: null,
			requestpoid: null,
			uuid: null,
			barcode: null,
			formValid: true,
			scroll_top: false,
			createDialog: true,
			pageLoading: false,
			contentLoading: false,
			manageCategoryDialog: false,
			minDate: new Date().toISOString().substr(0, 10),
			terms_and_condition: {
				id: null,
				description: null,
			},
			location: {
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
			services: {
				name: null,
				description: null,
				reference: null,
				barcode: null,
				charges: null,
				category: null,
				image: null,
			},
			line_items_calculations: {},
			selectedCustomer: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			customer: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			selectedSupplier: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			supplier: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			purchase_order: {
				purchase_order_number: null,
				reference_number: null,
				barcode: null,
				date: null,
				customer: null,
				customer_billing_address: null,
				contact_person: null,
				supplier_billing_address: null,
				supplier: null,
				project: null,
				files: [],
				files_approve: [],
				/* terms_and_conditions: null,
				terms_and_conditions_text: null, */
				admin_remark: null,
			},
			groupList: [],
			termsAndConditionList: [{ value: 1, text: "N-20" }],
			customerList: [],
			projectList: [],
			allProjectList: [],
			supplierList: [],
			customer_billing_address: null,
			customer_contact_person: null,
			supplier_billing_address: null,
			supplier_contact_person: null,
			poLineItems: [
				{
					isEditable: false,
					id: null,
					uuid: null,
					product: null,
					description: null,
					quantity: 1,
					quantity_type: null,
					discount: null,
					rate: 0,
					uom: null,
					tax_value: null,
					tax_amount: null,
					sub_total: 0,
					total: 0,
					product_type: null,
					order: 0,
				},
			],
		};
	},
	components: {
		TextInput,
		AutoCompleteInput,
		TextAreaInput,
		ImageUpload,
		ManageCategory,
		CreateDialog,
		POLineItems,
		FileUpload,
		//SelectTermsCondition,
		SelectCustomerSupplier,
		SelectSupplier,
		DatePicker,
		TooltipQuestion,
	},
	methods: {
		hideOnScroll(scroll) {
			if (scroll > 100) {
				this.scroll_top = true;
			} else {
				this.scroll_top = false;
			}
		},
		updateLineItems(items) {
			console.log({ updateLineItems: items });
		},
		supplierChange($event) {
			const supplier = $event && $event.id ? $event.id : null;
			if (this.$refs.poLineItemsRef) {
				this.$refs.poLineItemsRef.getRelatedProduct(supplier);
			}
		},
		async getCustomer(id) {
			const param = { customer: id };
			const { billing_address, contact_person } = await GetCustomer(param);
			this.customer_billing_address = billing_address;
			this.customer_contact_person = contact_person;
		},
		async getSupplier(id) {
			const param = { supplier: id };
			const { billing_address, contact_person } = await GetCustomer(param);
			//console.log({param:data});
			this.supplier_billing_address = billing_address;
			this.supplier_contact_person = contact_person;
		},
		getSubGroup() {
			console.log({ services_category: this.services.category });
		},
		getCalculation(params) {
			let obj = params;
			//console.log(obj, "obj");
			for (const property in obj) {
				obj[property] = Number(obj[property]);
			}
			this.line_items_calculations = obj;
		},
		async updateOrCreate(typestatus) {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}
			const order_length = this.poLineItems.length;
			//console.log(order_length, "order_length");
			const items = map(this.poLineItems, (row) => {
				return {
					id: row.id ? row.id : null,
					uuid: row.uuid ? row.uuid : null,
					product: row.product ? row.product : null,
					description: row.description ? row.description : null,
					quantity: row.quantity ? Number(row.quantity) : 0,
					approve_quantity: row.approve_quantity ? Number(row.approve_quantity) : 0,
					rate: row.rate ? Number(row.rate) : 0,
					uom: row.uom ? row.uom : null,
					tax_value: row.tax_value ? row.tax_value : 0,
					tax_amount: row.tax_amount ? row.tax_amount : 0,
					sub_total: row.total ? Number(row.total) : 0,
					total: row.total ? Number(row.total) : 0,
					order: row.order ? Number(row.order) : order_length + 1,
					product_type: row.product_type ? row.product_type : "inventory",
				};
			}).filter((row) => row.product != null);
			//console.log(items.length, "items.length");
			if (items.length == 0) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: "Line Items Required" }]);
				return false;
			}
			const files = map(this.purchase_order.files, (row) => {
				return { ...row, file: null };
			});
			const files_approve = map(this.purchase_order.files_approve, (row) => {
				return { ...row, file: null };
			});
			const formData = {
				...this.purchase_order,
				...this.line_items_calculations,
				barcode: null,
				typestatus,
				files: files,
				files_approve: files_approve,
				terms_and_conditions: this.terms_and_condition.id,
				terms_and_conditions_text: this.terms_and_condition.description,
				supplier: this.supplier.id,
				supplier_address:
					this.supplier && this.supplier.billing_address_id ? this.supplier.billing_address_id : null,
				supplier_contact_person:
					this.supplier && this.supplier.contact_person_id ? this.supplier.contact_person_id : null,
				customer: this.customer.id,
				customer_address:
					this.customer && this.customer.billing_address_id ? this.customer.billing_address_id : null,
				customer_contact_person:
					this.customer && this.customer.contact_person_id ? this.customer.contact_person_id : null,
				line_items: items,
			};

			try {
				_this.pageLoading = true;
				if (_this.uuid) {
					const { uuid } = await UpdatePurchaseOrder(_this.uuid, formData);

					_this.$router.replace({
						name: "request-po-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Request PO has been updated." },
					]);
				} else {
					const { uuid } = await CreateRequestPo(formData);

					_this.$router.replace({
						name: "request-po-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Request PO has been created." },
					]);
				}
				// clearing local storage data
				this.clearLocalData();
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async getPurchaseOrder() {
			this.pageLoading = true;
			this.contentLoading = true;
			await GetPurchaseOrder(this.uuid)
				.then((data) => {
					//console.log(data, "1234");
					this.barcode = data.barcode;
					this.purchase_order = {
						id: data.id,
						uuid: data.uuid,
						purchase_order_number: data.purchase_order_number,
						reference_number: data.reference_number,
						status_value: data.status_value,
						barcode: data.barcode,
						date: moment(data.date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD"),
						customer: data.customer && data.customer.id,
						customer_billing_address: data.customer_address ? data.customer_address.id : null,
						supplier_billing_address: data.supplier_address ? data.supplier_address.id : null,
						contact_person: null,
						supplier: data.supplier && data.supplier.id,
						project: data.project && data.project.id,
						files: [],
						files_approve: [],
						terms_and_conditions: data.terms_and_conditions,
						terms_and_conditions_text: data.terms_and_conditions_text,
						admin_remark: data.admin_remark,
					};
					this.selectedCustomer = {
						id: data.customer && data.customer.id,
						billing_address_id: data.customer_address ? data.customer_address.id : null,
						contact_person_id: data.customer_contact_person ? data.customer_contact_person.id : null,
					};
					this.selectedSupplier = {
						id: data.supplier && data.supplier.id,
						billing_address_id: data.supplier_address ? data.supplier_address.id : null,
						contact_person_id: data.supplier_contact_person ? data.supplier_contact_person.id : null,
					};

					this.customer_billing_address = data.customer_billing_address
						? data.customer_billing_address
						: null;
					this.supplier_billing_address = data.supplier_billing_address
						? data.supplier_billing_address
						: null;

					const __lineitems = map(data.line_items, (row) => {
						//console.log(row.original_product.status, "status");
						let pendingstatus = false;
						if (row.original_product && row.original_product.status && row.original_product.status == 3) {
							pendingstatus = true;
						}
						return { ...row, isEditable: false, pendingstatus };
					});
					//console.log(__lineitems, "__lineitems");
					this.poLineItems = [...__lineitems, ...this.poLineItems];
					this.terms_and_condition = {
						id: data.terms_and_conditions,
						description: data.terms_and_conditions_text,
					};
					const _calculation = {
						tax_value: data.tax_value,
						taxable_amount: data.taxable_amount,
						tax_amount: data.tax_amount,
						discount_value: data.discount_value,
						discount_value_type: data.discount_value_type,
						discount_amount: data.discount_amount,
						discount_type: data.discount_type,
						adjustment: data.adjustment,
						tax_applied: data.tax_applied,
						adjustment_amount: data.adjustment_amount && data.adjustment_amount,
						sub_total: data.sub_total,
						total: data.total,
					};
					EventBus.$emit("reload:calculation", _calculation);

					const payload = {
						module: "request-order",
						id: this.purchase_order.id,
					};
					this.getProjectListiing(payload);

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Purchase Order", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoading = false;
				});
		},
		getCategories(param) {
			this.groupList = param;
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Request PO";
			}
			return "Create New Request PO";
		},
		clearLocalData() {
			deleteData("create_purchase_order");
			deleteData("create_purchase_order_project");
			deleteData("po_line_items");
		},
		savetoLocal() {
			if (!this.uuid) {
				let create_purchase_order = {
					purchase_order_number: this.purchase_order.purchase_order_number,
					reference_number: this.purchase_order.reference_number,
					barcode: this.purchase_order.barcode,
					date: this.purchase_order.date,
					project: this.purchase_order.project,
					/* supplier: {
						id: this.supplier.id,
						contact_person_id: this.supplier.contact_person_id,
						billing_address_id: this.supplier.billing_address_id,
					}, */
					// project: this.purchase_order.project,
					//supplier: this.purchase_order.supplier,
					terms_and_conditions: this.purchase_order.terms_and_conditions,
					terms_and_conditions_text: this.purchase_order.terms_and_conditions_text,
					admin_remark: this.purchase_order.admin_remark,
				};
				saveData("create_purchase_order", create_purchase_order);
			}
		},
		savePurchaseOrderToLocal() {
			saveData("create_purchase_order_project", this.purchase_order.project);
		},
		initLocalData() {
			// getting service local storage data
			let localSavePurchaseOrder = getData("create_purchase_order");
			if (localSavePurchaseOrder) {
				this.purchase_order.purchase_order_number = localSavePurchaseOrder.purchase_order_number;
				this.purchase_order.reference_number = localSavePurchaseOrder.reference_number;
				this.purchase_order.barcode = localSavePurchaseOrder.barcode;
				this.purchase_order.date = localSavePurchaseOrder.date;
				this.purchase_order.project = localSavePurchaseOrder.project;
				(this.selectedSupplier = {
					id:
						localSavePurchaseOrder.supplier &&
						localSavePurchaseOrder.supplier.id &&
						localSavePurchaseOrder.supplier.id,
					contact_person_id:
						localSavePurchaseOrder.supplier &&
						localSavePurchaseOrder.supplier.contact_person_id &&
						localSavePurchaseOrder.supplier.contact_person_id,
					billing_address_id:
						localSavePurchaseOrder.supplier &&
						localSavePurchaseOrder.supplier.billing_address_id &&
						localSavePurchaseOrder.supplier.billing_address_id,
				}),
					// this.purchase_order.project = localSavePurchaseOrder.project;
					//this.purchase_order.supplier = localSavePurchaseOrder.supplier;
					(this.purchase_order.terms_and_conditions = localSavePurchaseOrder.terms_and_conditions);
				this.purchase_order.terms_and_conditions_text =
					localSavePurchaseOrder.terms_and_conditions_text;
				this.purchase_order.admin_remark = localSavePurchaseOrder.admin_remark;
			}
			let localSavePurchaseOrderProject = getData("create_purchase_order_project");
			if (localSavePurchaseOrderProject) {
				this.purchase_order.project = localSavePurchaseOrderProject;
			}
			const po_items = getData("po_line_items");
			//console.log({ po_items: this.poLineItems });
			if (po_items && po_items.length) {
				this.poLineItems = [...po_items];
			}
		},
		getProjectListiing(payload) {
			this.$store.dispatch(GET_PROJUCT_DROPDOWN, payload).then(({ projects }) => {
				this.AllProjectList = projects;
				/* map(projects, (row) => {
					return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
				}); */
			});
		},
		getRelatedProject($event) {
			const customer = $event && $event.id;
			this.projectList = filter(this.AllProjectList, (row) => {
				return row.customer == customer;
			});
		},
	},
	beforeMount() {
		this.initLocalData();
	},
	mounted() {
		this.$store.dispatch(SET_LOCAL_DB).then(() => {
			this.groupList = this.localDB("groups", []);
			this.customerList = this.localDB("customers", []);
			this.supplierList = this.localDB("suppliers", []);
			// this.projectList = this.localDB("projects", []);
		});
		/* this.genrateBarcode(); */
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Purchase Order", disabled: true },
			{ text: "Create", disabled: true },
		]);

		this.statusapprove = this.$route.query.status;
		this.requestpoid = toSafeInteger(this.$route.query.requestpoid);

		const { name, params } = this.$route;

		console.log(name, "name1");
		if (name === "request-po-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.getPurchaseOrder();
		} else {
			//alert(123);
			this.genrateBarcode("request-po").then((output) => {
				this.purchase_order.barcode = output;
			});
			const payload = {
				module: "request-po",
				id: null,
			};
			this.getProjectListiing(payload);
		}
		if (this.purchase_order.supplier) {
			this.getSupplier(this.purchase_order.supplier);
		}
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	watch: {
		poLineItems(param) {
			console.log({ param });
		},
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
		this.clearLocalData();
	},
};
</script>
